<template>
    <div class="app_download">
        <Header></Header>
        <div class="app_content">
            <div class="tab">
              <div class="_wrap">
                <div class="btn">
                  <div class="icon"><img src="~@/assets/common/android.png" alt=""></div>
                  <div class="text">Android 用户下载</div>
                </div>
              </div>
              <div class="_wrap _wrap2">
                <div class="btn">
                  <div class="icon"><img src="~@/assets/common/ios.png" alt=""></div>
                  <div class="text">IOS 用户下载</div>
                </div>
                <div class="hint">iOS的用户请到appStore下载安装</div>
              </div>
            </div>
            <div class="ad_content">
                <img src="~@/assets/common/app_download.png" alt="">
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
export default {
    data(){
        return{
        }
    },
    components:{
        Header,
        Footer
    }
}
</script>

<style lang="less">
  .app_download{
    .app_content{
      min-height:calc(100vh - 86px - 60px);
      .ad_content{
        max-width: @max_width;
        margin: 0 auto;
      }
      .tab{
        max-width: @max_width;
        margin: 0 auto;
        padding-top:30px;
        display: flex;
        justify-content: center;
        ._wrap{
          .btn{
            display: flex;
            align-items: center;
            background: #01B671;
            color: #fff;
            border-radius: 8px;
            width: 190px;
            padding: 5px 0;
            .icon{
              padding: 0 10px;
            }
          }
          .hint{
            padding-top: 5px;
          }
        }
        ._wrap2{
          margin-left: 20px;
          opacity: 0.3;
        }
      }
    }
  }
</style>